import React, { useState, useEffect, useMemo } from "react"
import { useFetch } from "react-async"
import InvestorRelationsLayout from '../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import { Link } from "gatsby"
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'

export const query = graphql`
  query ($locale: String!) {
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }) {
      overview
      calendar {
        date
        event
      }
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function InvestorRelations({ data, pageContext }) {
  const { overview } = data.datoCmsInvestorRelationsPage
  const nextCalendarItem = useMemo(() =>  {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return data.datoCmsInvestorRelationsPage.calendar.find(({ date }) => new Date(date) >= today)
  }, [data])

  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <div dangerouslySetInnerHTML={{
              __html: overview,
            }}></div>
            <div className="grid">
              <div className="col col--md-6">
                <EventsTitle />
                {nextCalendarItem ? nextCalendarItem.date : null} &mdash; {nextCalendarItem ? nextCalendarItem.event : null}
                <CalendarLink />
              </div>
              <PressNews />
            </div>
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}

const EventsTitle = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <h2>{language=="sv" ? 'Kommande händelser' : 'Upcoming events'}</h2>
  )
}

const CalendarLink = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <div className="margin-top-small">
      {language=="sv" ? <Link to='/investerarrelationer/kalender'>Visa allt</Link>
      : <Link to='/en/investerarrelationer/kalender'>Show all</Link>}
    </div>
  )
}

const PressNews = () => {
  const { data: pressReleaseData, run } = useFetch('/.netlify/functions/feed', { method: 'GET' })
  const [feed, setFeed] = useState(null);

  useEffect(() => {
    run()
  }, [run]);

  if (pressReleaseData) {
    if (!pressReleaseData.bodyUsed) { pressReleaseData.json().then(setFeed) }
  }

  const language = React.useContext(LocaleContext).language

  return (
    <div className="col col--md-6">
        {language=="sv" ? <div>
          <h2>Senaste pressreleaser</h2>
          {feed && feed.Releases[0] && feed.Releases[0].Title}
          {!feed && "...loading"}
          {feed && !feed.Releases[0] && 'Ingenting att vissa nu'}
          <div className="margin-top-small">
            <Link to="/investerarrelationer/press-nyheter">Visa allt</Link>
          </div>
        </div> :
        <div>
        <h2>Latest press releases</h2>
        {feed && feed.Releases[0] && feed.Releases[0].Title}
        {!feed && "...loading"}
        {feed && !feed.Releases[0] && 'Nothing to show currently'}
        <div className="margin-top-small">
          <Link to="/en/investerarrelationer/press-nyheter">Show all</Link>
        </div>
      </div>
        
        }
        
    </div>
  )
}